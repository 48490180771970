import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import DefaultButton from "../../components/button/DefaultButton";
import DefaultOutlineButton from "../../components/button/DefaultOutlineButton";
import HamburgerIcon from "../../components/Icons/HamburgerIcon";
import DownArrowIcon from "../../components/Icons/DownArrowIcon";
import BuildingIcon from "../../components/Icons/BuildingIcon";
import QouteIcon from "../../components/Icons/QouteIcon";
import PhoneIcon from "../../components/Icons/PhoneIcon";
import BreakpointDown from "../../components/Media/BreakpointDown";
import BreakpointUp from "../../components/Media/BreakpointUp";
import QuotePopup from "../QuotePopup";

import Logo from "../../images/logo.svg";

import CarportsDropdown from "./DropDown/carports";
import GaragesDropdown from "./DropDown/garages";
import BarnsDropdown from "./DropDown/barns";
import OtherBuildingsDropdown from "./DropDown/other-buildings";
import RtoFinancingDropdown from "./DropDown/rto-financing";
import ResourcesDropdown from "./DropDown/resources";
import AboutDropdown from "./DropDown/about";

const HeaderWrapper = styled.header`
  position: fixed;
  z-index: 11;
  left: 0;
  right: 0;
  top: 0;
  background-color: #fff;
  ${BreakpointDown.xl`
    box-shadow:0 0px 8px rgba(0,0,0,0.2);
  `}
`;
const Wrap = styled.div`
  display: ${(props) => props.display};
`;
const Nav = styled.nav`
  background: #002337;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${BreakpointDown.xl`  
    padding: 60px 0;  
    // flex-direction: column-reverse;
    width: 320px;
    position: absolute;
    top: 60px;
    right: 0;
    height: calc(100vh - 120px);
    z-index: 99;
    transition: all 0.3s ease 0s;
    transform: translate3d(320px,0,0);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 15px;
    overflow-y: auto;
  `}
`;

const NavBrand = styled(Link)`
  display: block;
  padding: 0px 10px;
  background-color: #fff;
  position: absolute;
  box-shadow: 0px 10px 45px rgba(0, 0, 0, 0.3);
  left: 15px;
  top: 0;
  z-index: 100;
  overflow: hidden;
  height: 110px;
  width: 150px;
  & svg {
    width: 100%;
  }
  & img {
    width: 100%;
    padding-top: 10px;
  }
  @media (max-width: 1200px) {
    width: 100px;
    left: 0;
    height: 82px;
    & img {
      margin-top: 5px;
    }
  }
  @media (max-width: 500px) {
    width: 70px;
    padding: 0 5px;
    height: 60px;
    & svg {
      margin-top: -20px;
    }
    & img {
      margin-top: 0px;
    }
  }
`;
const TopNav = styled.div`
  text-align: right;
  padding: 9px 0;
  margin: 0 -5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > a,
  .quote,
  .hamburger {
    margin: 0 5px;
    display: inline-flex;
    .btn {
      ${BreakpointDown.lg`
        padding:10px;
      `}
      .text {
        ${BreakpointDown.lg`
          display:none;
        `}
      }
    }
    &:first-child {
      ${BreakpointDown.lg`
        display:none;
      `}
    }
  }
  & .hamburger {
    display: none;
    .icon {
      width: 20px;
    }
    &:hover {
      cursor: pointer;
    }
    ${BreakpointDown.xl`
      display: inline-flex;
    `}
  }
`;

const NavbarNav = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 0;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  ${BreakpointDown.xl`
    flex-direction:column;
    margin:0;
    width:100%;
    flex: auto;
  `}
`;
const NavItem = styled.div`
  margin: 0 5px;
  &:hover {
    cursor: pointer;
  }
  .dropdown{
    ${BreakpointUp.xl`      
      position: relative;
      display:block;
      visibility: hidden;
      opacity: 0;
      transform: scale(0.8) translateY(-12%);
      transition: 0.4s cubic-bezier(0.3, 0, 0, 1.3);
    `}
  }
  &:hover{
    cursor:pointer;    
    .dropdown{
      ${BreakpointUp.xl`
        display: block;
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
        transform: none;
      `}
    }
  }
  ${BreakpointDown.xl`        
    width:100%;
  `}
  @media (min-width: 1280px) {
    margin: 0 10px;
  }
  &.active {
    & .nav-link {
      color: #fff;
    }
  }
  .dropdown-menu.right{
    ${BreakpointUp.xl`
      right:0;
    `}
  }
`;
const NavLink = styled.span`
  display: flex;
  align-items: center;
  white-space: nowrap; 
  cursor: pointer;
  position: relative;
  padding: 12px 10px;
  line-height: 26px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  ${BreakpointDown.xl`    
    padding: 12px 24px;
  `}
  .text{
    + .caret{
      margin-left:6px;
      ${BreakpointDown.xl`
        margin-left:auto;
        width: 24px;
        height: 24px;
        background: #1a3d51;
      `}
    }
  }
  .caret{
    display: flex;
    align-items:center;
    justify-content:center;
    transition: all 0.3s ease;
    width: 10px;
    height: 10px;
    > svg{
      fill:#fff;
      width:10px;
      transform: rotate(0deg);
    }
    
  }
  a{
    color:#fff;    
    &:hover{
      color:#fff;   
    }
  }
  &:hover{
    color:#fff;     
    > svg{
      fill:#fff;     
    }    
  } 
`;

const DropDown = styled.div`
  display: ${(props) => props.display};
`;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenu: true,
      isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleBarns: false,
      isVisibleOther: false,
      isVisibleRtoFinancing: false,
      isVisibleResources: false,
      isVisibleAbout: false,
      isVisibleQuote: false,
    };
    this.toggleActive = this.toggleActive.bind(this);
    this.showQuote = this.showQuote.bind(this);
    this.onClosePopup = this.onClosePopup.bind(this);
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.body.classList.remove("menu-open");
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (
      event.target.parentNode.tagName !== "A" &&
      event.target.tagName !== "A"
    ) {
      this.setState({
        isVisibleCarports: false,
        isVisibleGarages: false,
        isVisibleBarns: false,
        isVisibleOther: false,
        isVisibleRtoFinancing: false,
        isVisibleResources: false,
        isVisibleAbout: false,
      });
    }
  };
  showQuote() {
    this.setState({
      isVisibleQuote: true,
    });
    if (typeof window !== `undefined`) {
      document.body.classList.add("modal-open");
    }
  }
  onClosePopup() {
    this.setState({
      isVisibleQuote: false,
    });
    if (typeof window !== `undefined`) {
      document.body.classList.remove("modal-open");
    }
  }
  toggleActive() {
    this.setState({
      isMenu: !this.state.isMenu,
    });
    document.body.classList.toggle("menu-open", this.state.isMenu);
  }
  showCarportsDropDown() {
    let flag = !this.state.isVisibleCarports;
    this.setState({
      isVisibleCarports: flag,
      isVisibleGarages: false,
      isVisibleBarns: false,
      isVisibleOther: false,
      isVisibleRtoFinancing: false,
      isVisibleResources: false,
      isVisibleAbout: false,
    });
  }
  showGaragesDropDown() {
    let flag = !this.state.isVisibleGarages;
    this.setState({
      isVisibleCarports: false,
      isVisibleGarages: flag,
      isVisibleBarns: false,
      isVisibleOther: false,
      isVisibleRtoFinancing: false,
      isVisibleResources: false,
      isVisibleAbout: false,
    });
  }
  showBarnsDropDown() {
    let flag = !this.state.isVisibleBarns;
    this.setState({
      isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleBarns: flag,
      isVisibleOther: false,
      isVisibleRtoFinancing: false,
      isVisibleResources: false,
      isVisibleAbout: false,
    });
  }
  showOtherBuildingsDropDown() {
    let flag = !this.state.isVisibleOther;
    this.setState({
      isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleBarns: false,
      isVisibleOther: flag,
      isVisibleRtoFinancing: false,
      isVisibleResources: false,
      isVisibleAbout: false,
    });
  }
  showRtoFinancingDropDown() {
    let flag = !this.state.isVisibleResources;
    this.setState({
      isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleBarns: false,
      isVisibleOther: false,
      isVisibleRtoFinancing: flag,
      isVisibleResources: false,
      isVisibleAbout: false,
    });
  }
  showResourcesDropDown() {
    let flag = !this.state.isVisibleResources;
    this.setState({
      isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleBarns: false,
      isVisibleOther: false,
      isVisibleRtoFinancing: false,
      isVisibleResources: flag,
      isVisibleAbout: false,
    });
  }
  showAboutDropDown() {
    let flag = !this.state.isVisibleAbout;
    this.setState({
      isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleBarns: false,
      isVisibleOther: false,
      isVisibleRtoFinancing: false,
      isVisibleResources: false,
      isVisibleAbout: flag,
    });
  }
  render() {
    const {
      isVisibleCarports,
      isVisibleGarages,
      isVisibleBarns,
      isVisibleOther,
      isVisibleRtoFinancing,
      isVisibleResources,
      isVisibleAbout,
      isVisibleQuote,
    } = this.state;
    return (
      <>
      <HeaderWrapper>
        <Wrap className="container" display={isVisibleQuote ? "none" : "block"}>
          <TopNav>
            <a
              href="https://mrcarports.sensei3d.com"
              target="_blank"
              rel="noreferrer"
            >
              <DefaultOutlineButton
                icon={<BuildingIcon />}
                text="Design your Building"
              />
            </a>
            <span
              role="button"
              tabIndex={0}
              className="quote"
              onClick={this.showQuote}
              onKeyDown={this.showQuote}
            >
              <DefaultOutlineButton icon={<QouteIcon />} text="Free Quote" />
            </span>
            <a href="tel:8773505464">
              <DefaultButton
                animation="true"
                icon={<PhoneIcon />}
                text="(877) 350-5464"
              />
            </a>
            <span
              className="hamburger"
              role="button"
              tabIndex={0}
              onClick={this.toggleActive}
              onKeyDown={this.toggleActive}
            >
              <DefaultButton icon={<HamburgerIcon />} />
            </span>
          </TopNav>
          <NavBrand to="/">
            <img src={Logo} alt="Mr. Carports" />
          </NavBrand>
          <Nav className="nav">
            <NavbarNav id="navMenu">
              <NavItem >
                <NavLink className="nav-link">
                  <Link to="/metal-buildings" className="text" tabIndex="0">Metal Buildings</Link>
                  <button aria-label="link" className="caret" onClick={() => this.showOtherBuildingsDropDown()}><DownArrowIcon /></button>
                </NavLink>
                <DropDown className="dropdown" display={isVisibleOther ? "block" : "none"}>
                  <OtherBuildingsDropdown />
                </DropDown>
              </NavItem>
              <NavItem>
                <NavLink className="nav-link">
                  <Link to="/metal-barns" className="text" tabIndex="0">Metal Barns</Link>
                  <button aria-label="link" className="caret" onClick={() => this.showBarnsDropDown()}><DownArrowIcon /></button>
                </NavLink>
                <DropDown className="dropdown" display={isVisibleBarns ? "block" : "none"}>
                  <BarnsDropdown />
                </DropDown>
              </NavItem>
              <NavItem>
                <NavLink className="nav-link">
                  <Link to="/metal-garages" className="text" tabIndex="0">Metal Garages</Link>
                  <button aria-label="link" className="caret" onClick={() => this.showGaragesDropDown()}><DownArrowIcon /></button>
                </NavLink>
                <DropDown className="dropdown" display={isVisibleGarages ? "block" : "none"}>
                  <GaragesDropdown />
                </DropDown>
              </NavItem>
              <NavItem>
                <NavLink className="nav-link">
                  <Link to="/metal-carports" className="text" tabIndex="0">Metal Carports</Link>
                  <button aria-label="link" className="caret" onClick={() => this.showCarportsDropDown()}><DownArrowIcon /></button>
                </NavLink>
                <DropDown className="dropdown" display={isVisibleCarports ? "block" : "none"}>
                  <CarportsDropdown />
                </DropDown>
              </NavItem>
              <NavItem >
                <NavLink className="nav-link">
                  <span className="text" tabIndex="0">RTO/Financing</span>
                  <button aria-label="link" className="caret" onClick={() => this.showRtoFinancingDropDown()}><DownArrowIcon /></button>
                </NavLink>
                <DropDown className="dropdown" display={isVisibleRtoFinancing ? "block" : "none"}>
                  <RtoFinancingDropdown />
                </DropDown>
              </NavItem>
              <NavItem>
                <NavLink className="nav-link">
                  <span className="text" tabIndex="0">Resources</span>
                  <button aria-label="link" className="caret" onClick={() => this.showResourcesDropDown()}><DownArrowIcon /></button>
                </NavLink>
                <DropDown className="dropdown" display={isVisibleResources ? "block" : "none"}>
                  <ResourcesDropdown />
                </DropDown>
              </NavItem>
              <NavItem>
                <NavLink className="nav-link">
                  <Link to="/about-us" className="text" tabIndex="0">About Us</Link>
                  <button aria-label="link" className="caret" onClick={() => this.showAboutDropDown()}><DownArrowIcon /></button>
                </NavLink>
                <DropDown className="dropdown" display={isVisibleAbout ? "block" : "none"}>
                  <AboutDropdown />
                </DropDown>
              </NavItem>
            </NavbarNav>
          </Nav>
        </Wrap>
      </HeaderWrapper>
      <QuotePopup
        isVisible={this.state.isVisibleQuote}
        onClose={this.onClosePopup}
      />
      </>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
