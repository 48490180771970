import React from 'react'
import styled, { keyframes } from 'styled-components'
import { BaseButton } from '../../components/section'

const rotate = keyframes`
  0% {
    transform: rotate(0) skew(1deg);
  }
  10% {
    transform rotate(-16deg) skew(1deg);
	}
	20% {
	  transform rotate(16deg) skew(1deg);
	}
	30% {
	  transform rotate(-16deg) skew(1deg);
	}
	40% {
	  transform rotate(16deg) skew(1deg);
	}
	50% {
	  transform rotate(0) skew(1deg);
	}
	100% {
	  transform rotate(0) skew(1deg);
	}
`;

const DefaultBtn  = styled(BaseButton)`		
	background: #f99207;
	background: -moz-linear-gradient(top,  #f99207 0%, #f97007 100%);
	background: -webkit-linear-gradient(top,  #f99207 0%,#f97007 100%);
	background: linear-gradient(to bottom,  #f99207 0%,#f97007 100%);	
	color:#fff;
	border-color: #FF9F1D;
	> .icon{	
		& svg{
			fill:#fff;
		}
	}
	&:hover{		
		color:#fff;		
		> .icon{
			& svg {
				fill:#fff;
			}
		}
	}	
`

const Rotate = styled.span`
	display: inline-flex;
	animation: ${rotate} 1s infinite ease-in-out;
	& .icon{	
		& svg{
			fill:#fff;
		}
	}
`

const DefaultButton = (props) => {
	const { icon, text, animation } = props;
	if (animation) {
		return(
			<DefaultBtn className="btn">
				<span className="icon"><Rotate>{icon}</Rotate></span>
				<span className="text">{text}</span>
			</DefaultBtn>
		)	
	}
	return(
		<DefaultBtn className="btn">
			<span className="icon">{icon}</span>
			<span className="text">{text}</span>
		</DefaultBtn>
	)
}

export default DefaultButton