import styled from 'styled-components'
import ListArrow from "../../images/list-arrow.svg"

export const Section = styled.section`
	padding-top: ${props=>props.pt};
	padding-bottom: ${props=>props.pb};
	background: ${props=>props.bg};
	margin-top: ${props=>props.mt};
	height:${props=>props.ht};
	text-align: ${props=>props.textAlign};	
	border-bottom:1px solid ${props=>props.bdrBottom};
	position: relative;	
	&:before{
		content:'';
		position:absolute;
		display:${props => (props.bgBefore ? 'block' : 'none')};
		top:${props => props.topBefore};
		right:${props => props.rightBefore};
		bottom:${props => props.bottomBefore};
		right:${props => props.leftBefore};
		background:${props => props.bgBefore};
		background-size:${props => props.bgBeforeSize};
		background-repeat: ${props => props.bgBeforeRepeat};
		width:${props => props.bgBeforeWidth};
		height:${props => props.bgBeforeHeight};
		opacity:${props => props.opacityBefore};
	}
	&:after{
		content:'';
		position:absolute;
		display:${props => (props.bgAfter ? 'block' : 'none')};
		top:${props => props.topAfter};
		right:${props => props.rightAfter};
		bottom:${props => props.bottomAfter};
		right:${props => props.leftAfter};
		background:${props => props.bgAfter};
		background-size:${props => props.bgAfterSize};
		background-repeat: ${props => props.bgAfterRepeat};
		width:${props => props.bgAfterWidth};
		height:${props => props.bgAfterHeight};
		opacity:${props => props.opacityAfter};
	}
	@media(max-width: 1200px){
		padding-top: ${props=>props.xpt};
		padding-bottom: ${props=>props.xpb};
		margin-top: ${props=>props.xmt};
		margin-bottom: ${props=>props.xmb};
	}
	@media(max-width: 767px){
		padding-top: ${props=>props.mpt};
		padding-bottom: ${props=>props.mpb};
	}
`
Section.defaultProps = {
	bg: "white",
	textAlign: "left",
	bdrBottom:"transparent"

}

export const SectionTitle = styled.h2`	
	max-width: ${props=>props.maxWidth};
	color: ${props=>props.color};
	text-align: ${props=>props.textAlign};
	margin-top: ${props=>props.mt};	
	margin-bottom: ${props=>props.mb};	
	margin-left:${props=>props.ml};
	margin-right:auto;
	width:100%;
	@media(max-width: 1200px){
		margin-bottom: ${props=>props.xmb};	
	}
`

SectionTitle.defaultProps = {
	textAlign: "center",
	color: "#002337",
	maxWidth: "100%",
	mt: "auto",
	ml: "auto",
	mb: "10px",
}

export const SectionPageTitle = styled.h1`	
	max-width: ${props=>props.maxWidth};
	color: ${props=>props.color};	
	margin-top: ${props=>props.mt};	
	margin-bottom: ${props=>props.mb};	
	margin-left:${props=>props.ml};
	text-align: ${props=>props.textAlign};
	margin-right:auto;
	width:100%;
`

SectionPageTitle.defaultProps = {
	color: "#002337",
	maxWidth: "100%",
	mt: "auto",
	ml: "auto",
	mb: "10px",
	textAlign: "center"
}


export const SectionLead = styled.p`
	margin-left:${props=>props.ml};
	margin-right:auto;
	text-align: ${props=>props.textAlign};
	margin-bottom: ${props=>props.mb};
	max-width:${props=>props.maxWidth};
	width:100%;
	font-size: 16px;
	line-height: 26px;
	@media(min-width: 992px){
		font-size: 18px;
		line-height: 30px;
	}
`
SectionLead.defaultProps = {
	textAlign: "center",
	ml:"auto",
	mb: "30px",
	maxWidth: "100%"
}

export const SectionDescription = styled.div`
	margin-left:${props=>props.ml};
	margin-right:auto;
	text-align: ${props=>props.textAlign};
	margin-bottom: ${props=>props.mb};
	max-width:${props=>props.maxWidth};
	color:${props=>props.color};
	width:100%;
	font-size: 16px;
	line-height: 26px;
	@media(min-width: 992px){
		font-size: 18px;
		line-height: 30px;
	}
	& p{
		@media(min-width: 992px){
			font-size: 18px;
			line-height: 30px;
		}
		+ p{
			margin-top:20px;
		}
	}
	& a{
		color:#002337;
		font-weight: 500;
		&:hover, &:focus{
			color:#f99207;
		}
	}
`
SectionDescription.defaultProps = {
	textAlign: "center",
	ml:"auto",
	mb: "30px",
	color: "#231F20",
	maxWidth: "100%"
}

export const BreadCrumb = styled.div`	
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	margin:0 -2px;
	position:relative;
	z-index:2;
	top: ${props=>props.top};
	margin-bottom: ${props=>props.mb};
	& a{
		padding:0 2px;
		color: #6C7780;
		&:hover, &:focus{
			color:#002337;
		}
	}
	& span {
		display: inline-block;
		padding:0 2px;
		color: #F99207;
	}
`
BreadCrumb.defaultProps = {
	top: "0px",
	mb: "0px",
}


export const MainPanel = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;

	.sticky-wrapper {
		position: relative;
		top: 0;
	}
	.no-sticky {
		position: relative;
		top: 0;
	}
	@media(min-width: 992px){
		.sticky-wrapper {
			position: -webkit-sticky;  // required for Safari
			position: sticky;
			right: 0;
			width: 100%;
			top: 60px;
		}
	}
	@media(min-width: 1200px){
		.sticky-wrapper {
			top: 110px;
		}
	}

`
export const RightPannel = styled.div`
	position:relative;	
	padding-left:15px;
	padding-right:15px;
	width:100%;
	@media(min-width: 992px){
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
`

export const LeftPannel = styled.div`
	position:relative;
	padding-left:15px;
	padding-right:15px;
	width:100%;
	@media(min-width: 992px){
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
`

export const MarkdownHeading = styled.div`	
	position:relative;
	text-align: ${props=>props.textAlign};
	max-width: ${props=>props.maxWidth};
	width:100%;
	margin:0 auto 30px auto; 
`
MarkdownHeading.defaultProps = {
	textAlign: "left",
	maxWidth: "auto"
}

export const MarkdownContent = styled.div`
	display: ${props=>props.display};
	max-width:${props=>props.maxWidth};
	width:100%;
	margin:${props=>props.mt} auto 0 auto;
	padding-left:${props=>props.pl};
	padding-right:${props=>props.pr};	
	h2 {
		font-size: 24px;
		line-height: 34px;
		margin-bottom:10px;
		padding-top: 20px;
		@media (min-width: 992px) {
			font-size: 34px;
			line-height: 44px;
		}
	}
	h3 {
		font-size: 24px;
		line-height: 34px;
		margin-bottom: 5px;
		font-weight: 500;
	}
	h4 {
		font-size: 22px;
		line-height: 32px;
		margin-bottom: 5px;
		font-weight: 500;		
	}
	h5 {
		font-size: 20px;
		line-height: 30px;
		margin-bottom: 5px;
		font-weight: 500;	
	}

	& p {
		text-align: ${props=>props.textAlign};
		color: ${props=>props.color};
		margin-bottom:15px;		
	}
	& ul, & ol {
		list-style: none;
		margin: 0 0 0 20px;
		padding: 0;
		list-style: disc outside none;
		& li {
			padding-left:0;
			vertical-align: text-top;
			color:#231F20;
			font-weight:400;
			margin:0;
			line-height: 28px;
			+ li{
				margin-top:10px;
			}
			/* &:before {
				content: url(${ListArrow});
				display: inline-block;
				margin-left: -20px;
				width: 20px;
				vertical-align: top;
			} */
		}
	}
	& ol {
		column-count: 1;
		@media (min-width:567px) {
			column-count: 2;
		}
		@media (min-width: 768px) {
			column-count: 3;
		}
	}
	& a{
		color:#002337;
		font-weight:500;
		&:hover, &:focus{
			color:#F99207;
		}
	}
`
MarkdownContent.defaultProps = {
	textAlign: "left",
	maxWidth: "none",
	pl: "0",
	pr: "0",
	mt:"0",
	display: 'block',
}
export const SectionBanner = styled.section`
	padding:140px 0 90px;
	display: flex;
    flex-wrap:wrap;
    align-items: center;   
    background-image:${props => props.bgImg};
    background-repeat: no-repeat;
    background-position: 50% 100%;    
    background-size: cover;
	@media(max-width: 992px){
		padding:140px 0 60px;
	}
`

export const Badge = styled.div`
	background-color:${props=>props.bgColor};
	color:${props=>props.color};
	font-size:14px;
	line-height:18px;
	font-weight:700;
	padding:5px 15px;
	position:absolute;
	z-index:1;
	top:${props=>props.top};
	right:${props=>props.right};
	bottom:${props=>props.bottom};
	left:${props=>props.left};
	border-radius: 0 15px 15px 0;
	&:before, &:after {
		content: "";
		position: absolute;
	}
	&:before {
		width: 7px;
		height: 100%;
		top: 0;
		left: -6.5px;
		padding: 0 0 7px;
		background: inherit;
		border-radius: 5px 0 0 5px;
	}
	&:after {
		width: 7px;
		height: 7px;
		bottom: -2px;
		left: -6.5px;
		border-radius: 5px 0 0 5px;
		background: ${props=>props.bgAfterColor};
	}
`
Badge.defaultProps = {
	color:"#fff",
	bgColor:"#0BA200",
	bgAfterColor:"#0BA200",
	top:"15px",
	right:"auto",
	bottom:"auto",
	left:"0"
}


export const SectionHeader = styled.section`    
  padding-top: ${props=>props.pt};
  padding-bottom: ${props=>props.pb};
  background:${props=>props.bgColor};
  text-align:${props=>props.textAlign}; 

  @media(max-width: 1200px){
    padding-top: ${props=>props.xpt};
    padding-bottom: ${props=>props.xpb};
  }
`
SectionHeader.defaultProps = {	
	textAlign:"left"
}

export const Divider = styled.div`
	width: 100%;
	height: 1px;
	margin: ${props=>props.my} 0;
	border-bottom: 1px solid ${props=>props.borderColor};
`
Divider.defaultProps = {
	my: "0px",
	borderColor: "rgba(2, 3, 4, 0.1)"
}

export const Triangle = styled.div`
	display: ${props=>props.display};
  position: relative;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0px 25px 65px rgba(1, 9, 32, 0.2);

  & :after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0.5em;
    margin-top: -49px;
    top: ${props=>props.top};
    left: ${props=>props.left};
    right: ${props=>props.right};
    box-sizing: border-box;
    border: 0.5em solid;
    border-color: transparent transparent #fff #fff;
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -3px 3px 4px -2px rgba(0,0,0,0.1)
  }
`

export const FormControlLabel = styled.div`
	&.active {
		font-size: 75%;
    transform: translate3d(0, -100%, 0);
    opacity: 1;
    color: #999;
	}
`

export const BaseButton = styled.span`
	display: inline-flex;
	align-items: center;
	justify-content: center;	
	text-align:center;	
	border:1px solid transparent;	
	padding:10px 20px;
	font-size:16px;
	font-weight:700;
	border-radius:4px;	
	cursor: pointer;
	transition: color .5s ease-in-out,background-color .5s ease-in-out,border-color .5s ease-in-out,box-shadow .5s ease-in-out;
	> .text{
		line-height: 20px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		+ .icon{
			margin-left:10px;
		}
	}
	> .icon{
		height: 20px;		
		display: inline-flex;
		align-items: center;
		justify-content: center;
		+ .text{
			margin-left:10px;
		}	
		&:empty {
			display: none;
		}
	}
`

export const ExploreMore = styled.div`
	display: flex;
	align-items: center;
    justify-content:  ${props=>props.justifyContent};
	text-align: center;
	margin-top: ${props=>props.mt};
  	margin-bottom: ${props=>props.mb};
	& .btn{
		display: inline-flex;
		flex-direction: row-reverse;
		& svg{
			transition: transform 0.5s ease-out;
		}
		& .text{
			margin-left:0;
			margin-right:10px;
		}
		&:hover{
			& svg{
				transform: translateX(6px);
			}
		}

	}
`
ExploreMore.defaultProps = {
	mt: "0px",
	mb: "0px",
	justifyContent:"center",
}