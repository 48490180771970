import React from "react";
import styled from "styled-components";
import DefaultButton from "../button/DefaultButton";
import DefaultOutlineButton from "../button/DefaultOutlineButton";
import PhoneIcon from "../Icons/PhoneIcon";
import QouteIcon from "../Icons/QouteIcon";
import BreakpointUp from "../Media/BreakpointUp";
import QuotePopup from "../QuotePopup";
import Image from "../image";

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: -moz-linear-gradient(
      left,
      rgba(10, 78, 130, 0.8) 0%,
      rgba(0, 35, 55, 1) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(10, 78, 130, 0.8) 0%,
      rgba(0, 35, 55, 1) 100%
    );
    background: linear-gradient(
      to right,
      rgba(10, 78, 130, 0.8) 0%,
      rgba(0, 35, 55, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#800a4e82', endColorstr='#002337',GradientType=1 );
  }
`;
const SectionRequest = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  margin: 0 -15px;
  flex-direction: column;
  padding: 30px 0;
  text-align: center;
  ${BreakpointUp.lg`		
		flex-direction: inherit;
		padding:54px 0;
		text-align:left;
	`}
`;
const TextWrap = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`		
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
	`}

  strong {
    color: #fff;
    display: block;
    margin-bottom: 10px;
    font-family: "Fira Sans", serif;
    font-weight: 700;
    font-size: 34px;
    line-height: 44px;
  }
  p {
    color: #fff;
    font-family: "Fira Sans", serif;
    max-width: 975px;
    width: 100%;
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 30px;
    ${BreakpointUp.lg`
			margin-bottom:0;
		`}
  }
`;
const ButtonWrap = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`		
		flex: 0 0 auto;
		width: auto;
		max-width: none;
	`}

  & a {
    display: block;
    + div {
      margin-top: 20px;
    }
  }
  .btn {
    max-width: 248px;
    width: 100%;
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      + div {
        margin: 0 10px;
      }
    }
  }
`;

class RequestQuote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisibleQuote: false,
    };
    this.showQuote = this.showQuote.bind(this);
    this.onClosePopup = this.onClosePopup.bind(this);
  }
  showQuote() {
    this.setState({
      isVisibleQuote: true,
    });
    if (typeof window !== `undefined`) {
      document.body.classList.add("modal-open");
    }
  }
  onClosePopup() {
    this.setState({
      isVisibleQuote: false,
    });
    if (typeof window !== `undefined`) {
      document.body.classList.remove("modal-open");
    }
  }
  render() {
    const { isVisibleQuote } = this.state;
    const { ctaTitle, ctaDesc } = this.props;
    return (
      <>
        <BackgroundImage>
          <Image name="request-quote.jpg" alt="request-quote" />
        </BackgroundImage>
        <div className="container">
          <SectionRequest>
            <TextWrap>
              <strong>{ctaTitle}</strong>
              <p>{ctaDesc}</p>
            </TextWrap>
            <ButtonWrap>
              <a href="tel:8773505464">
                <DefaultButton icon={<PhoneIcon />} text="(877) 350-5464" />
              </a>
              <div
                role="button"
                onClick={this.showQuote}
                onKeyDown={this.showQuote}
              >
                <DefaultOutlineButton
                  icon={<QouteIcon />}
                  text="Request A Quote"
                />
              </div>
            </ButtonWrap>
          </SectionRequest>
        </div>
        <QuotePopup isVisible={isVisibleQuote} onClose={this.onClosePopup} />
      </>
    );
  }
}

export default RequestQuote;
