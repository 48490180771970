import React from "react";
import { Link } from "gatsby";

import { ViewLink } from "../../Link";
import ArrowIcon from "../../Icons/ArrowIcon";
import { DropDown, DropItem } from "./tags-component";
import { Divider } from "../../section";

class OtherBuildingsDropdown extends React.Component {
  render() {
    return (
      <DropDown className="dropdown-menu">
        <DropItem>
          <Link to="/metal-building-homes">
            <span>Metal Building Homes</span>
            <ArrowIcon />
          </Link>
        </DropItem>
        <Divider />
        <DropItem>
          <Link to="/commercial-metal-buildings">
            <span>Commercial Buildings</span>
            <ArrowIcon />
          </Link>
        </DropItem>
        <Divider />
        <DropItem>
          <Link to="/clear-span-buildings">
            <span>Clear Span Buildings</span>
            <ArrowIcon />
          </Link>
        </DropItem>
        <Divider />
        <DropItem>
          <Link to="/custom-metal-buildings">
            <span>Custom Buildings</span>
            <ArrowIcon />
          </Link>
        </DropItem>
        <Divider />
        <DropItem>
          <Link to="/metal-workshops">
            <span>Metal Workshops</span>
            <ArrowIcon />
          </Link>
        </DropItem>
        <Divider />
        <DropItem>
          <Link to="/metal-warehouses">
            <span>Metal Warehouses</span>
            <ArrowIcon />
          </Link>
        </DropItem>
        <ViewLink to="/metal-buildings">View All Metal Buildings</ViewLink>
      </DropDown>
    );
  }
}

export default OtherBuildingsDropdown;
