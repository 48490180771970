import React from "react";
import styled from "styled-components";
import axios from "axios";
import { navigate } from "gatsby";
import FormButton from "../button/FormButton";
import BreakpointUp from "../Media/BreakpointUp";
import CloseIcon from "../Icons/CloseIcon";
import ArrowIcon from "../Icons/ArrowIcon";
import {ExploreMore} from "../section";
import {
  Modal,
  ModalTitle,
  CloseButton,
  ModalDialog,
  ModalContent,
  ModalBody,
  ModalBackdrop,
} from "../Modal"

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
`;
const GridCol = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
`;
const GridTwoCol = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.lg`		
    flex: 0 0 50%;
    max-width: 50%;
	`}
`;

class QuotePopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      email: "",
      phone_no: "",
      state: "",
      zipcode: "",
      comment: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    const { id, value } = event.target;
    this.setState({
      [id]: value,
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    const data = {
      first_name: this.state.first_name,
      email: this.state.email,
      phone_no: this.state.phone_no,
      state: this.state.state,
      zipcode: this.state.zipcode,
      comment: this.state.comment,
    };
    axios({
      method: "post",
      url: "https://crm.senseicrm.com/api/create-lead-wordpress",
      data: data,
      headers: { Authorization: "Bearer " + process.env.FORM_CRM_TOKEN },
    })
      .then((res) => {
        document.body.classList.remove("modal-open");
        this.setState({
          first_name: "",
          email: "",
          phone_no: "",
          state: "",
          zipcode: "",
          comment: "",
        });
        navigate("/thank-you-request-a-quote");
      })
      .catch((error) => {
        alert(error);
      });
  }
  render() {
    const { isVisible } = this.props;
    // console.log(process.env.FORM_CRM_TOKEN);
    return (
      <>
      <Modal
        className={"modal fade " + (isVisible ? "show" : "")}
        style={{ display: isVisible ? "block" : "none" }}
      >
        <ModalDialog className="modal-dialog modal-xl modal-dialog-centered">
          <ModalContent className="modal-content">
            <ModalBody className="modal-body">
              <CloseButton className="close" onClick={this.props.onClose}>
                <CloseIcon />
              </CloseButton>
              <ModalTitle className="modal-title">Request A Quote</ModalTitle>
              <form
                id="quoteform"
                className="quoteform"
                onSubmit={this.handleSubmit}
              >
                <Grid>
                  <GridCol>
                    <div className="form-group-bordered">
                      <input
                        type="text"
                        id="first_name"
                        className="form-control"
                        onChange={this.handleChange}
                        required
                        placeholder="Name"
                      />
                    </div>
                  </GridCol>
                  <GridTwoCol>
                    <div className="form-group-bordered">
                      <input
                        type="email"
                        id="email"
                        className="form-control"
                        onChange={this.handleChange}
                        required
                        placeholder="Email Address"
                      />
                    </div>
                  </GridTwoCol>
                  <GridTwoCol>
                    <div className="form-group-bordered">
                      <input
                        type="tel"
                        id="phone_no"
                        className="form-control"
                        onChange={this.handleChange}
                        pattern="[0-9]{10}"
                        required
                        placeholder="Phone Number"
                      />
                    </div>
                  </GridTwoCol>
                  <GridTwoCol>
                    <div className="form-group-bordered">
                      <select
                        id="state"
                        className="form-control"
                        onChange={this.handleChange}
                      >
                        <option value="">State</option>
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        <option value="AZ">Arizona</option>
                        <option value="AR">Arkansas</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="DC">District Of Columbia</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="IA">Iowa</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="ME">Maine</option>
                        <option value="MD">Maryland</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MS">Mississippi</option>
                        <option value="MO">Missouri</option>
                        <option value="MT">Montana</option>
                        <option value="NE">Nebraska</option>
                        <option value="NV">Nevada</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NY">New York</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VT">Vermont</option>
                        <option value="VA">Virginia</option>
                        <option value="WA">Washington</option>
                        <option value="WV">West Virginia</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WY">Wyoming</option>
                      </select>
                    </div>
                  </GridTwoCol>
                  <GridTwoCol>
                    <div className="form-group-bordered">
                      <input
                        id="zipcode"
                        className="form-control"
                        onChange={this.handleChange}
                        required
                        placeholder="Zip Code"
                      />
                    </div>
                  </GridTwoCol>
                  <GridCol>
                    <div className="form-group-bordered">
                      <textarea
                        id="comment"
                        className="form-control"
                        onChange={this.handleChange}
                        required
                        placeholder="Ask Your Query"
                      />
                    </div>
                  </GridCol>
                  <GridCol>
                    <div className="form-action">
                      <ExploreMore>
                        <FormButton text="Submit" icon={<ArrowIcon />} />
                      </ExploreMore>
                    </div>
                  </GridCol>
                </Grid>
              </form>
              </ModalBody>
          </ModalContent>
        </ModalDialog>
      </Modal>
      {isVisible && (
        <ModalBackdrop className={"fade " + (isVisible ? "show" : "")} />
      )}
      </>
    );
  }
}

export default QuotePopup;
