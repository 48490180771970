import React from 'react'
import { Link } from 'gatsby'

import ArrowIcon from "../../Icons/ArrowIcon"
import { DropDown, DropItem } from "./tags-component"
import { Divider } from "../../section"

class RtoFinancingDropdown extends React.Component {
	render(){
		return(
			<DropDown className="dropdown-menu">
				<DropItem>
					<Link to='/rto'><span>Rent to Own</span><ArrowIcon /></Link>
				</DropItem>	
				<Divider />			
				<DropItem>
					<Link to='/financing'><span>Financing</span><ArrowIcon /></Link>
				</DropItem>
			</DropDown>
		)
	}
}

export default RtoFinancingDropdown
