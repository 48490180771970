import React from "react";
import { Link } from "gatsby";

import { ViewLink } from "../../Link";
import ArrowIcon from "../../Icons/ArrowIcon";
import { DropDown, DropItem } from "./tags-component";
import { Divider } from "../../section";

class GaragesDropdown extends React.Component {
  render() {
    return (
      <DropDown className="dropdown-menu">
        <DropItem>
          <Link to="/vertical-roof-garages">
            <span>A-Frame Vertical Garages</span>
            <ArrowIcon />
          </Link>
        </DropItem>
        <Divider />
        <DropItem>
          <Link to="/a-frame-roof-garages">
            <span>A-Frame Horizontal Garages</span>
            <ArrowIcon />
          </Link>
        </DropItem>
        <Divider />
        <DropItem>
          <Link to="/regular-roof-garages">
            <span>Regular Garages</span>
            <ArrowIcon />
          </Link>
        </DropItem>
        <Divider />
        <DropItem>
          <Link to="/one-car-garages">
            <span>One Car Garages</span>
            <ArrowIcon />
          </Link>
        </DropItem>
        <Divider />
        <DropItem>
          <Link to="/two-car-garages">
            <span>Two Car Garages</span>
            <ArrowIcon />
          </Link>
        </DropItem>
        <Divider />
        <DropItem>
          <Link to="/three-car-garages">
            <span>Three Car Garages</span>
            <ArrowIcon />
          </Link>
        </DropItem>
        <ViewLink to="/metal-garages">View All Garages</ViewLink>
      </DropDown>
    );
  }
}

export default GaragesDropdown;
