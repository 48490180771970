import React from "react";
import { Link } from "gatsby";

import { ViewLink } from "../../Link";
import ArrowIcon from "../../Icons/ArrowIcon";
import { DropDown, DropItem } from "./tags-component";
import { Divider } from "../../section";

class CarportsDropdown extends React.Component {
  render() {
    return (
      <DropDown className="dropdown-menu">
        <DropItem>
          <Link to="/vertical-roof-carports">
            <span>A-Frame Vertical Carports</span>
            <ArrowIcon />
          </Link>
        </DropItem>
        <Divider />
        <DropItem>
          <Link to="/a-frame-roof-carports">
            <span>A-Frame Horizontal Carports</span>
            <ArrowIcon />
          </Link>
        </DropItem>
        <Divider />
        <DropItem>
          <Link to="/regular-roof-carports">
            <span>Regular Carports</span>
            <ArrowIcon />
          </Link>
        </DropItem>
        <Divider />
        <DropItem>
          <Link to="/one-car-carports">
            <span>One Car Carports</span>
            <ArrowIcon />
          </Link>
        </DropItem>
        <Divider />
        <DropItem>
          <Link to="/two-car-carports">
            <span>Two Car Carports</span>
            <ArrowIcon />
          </Link>
        </DropItem>
        <Divider />
        <DropItem>
          <Link to="/three-car-carports">
            <span>Three Car Carports</span>
            <ArrowIcon />
          </Link>
        </DropItem>
        <Divider />
        <DropItem>
          <Link to="/rv-carports">
            <span>RV Carports</span>
            <ArrowIcon />
          </Link>
        </DropItem>
        <ViewLink to="/metal-carports">View All Carports</ViewLink>
      </DropDown>
    );
  }
}

export default CarportsDropdown;
