import React from "react"
import styled from "styled-components" 
import { Link } from "gatsby"
import RequestQuote from "../../components/RequestQuote"
import FacebookIcon from "../../components/Icons/FacebookIcon"
import TwitterIcon from "../../components/Icons/TwitterIcon"
import InstagramIcon from "../../components/Icons/InstagramIcon"
import PinterestIcon from "../../components/Icons/PinterestIcon"
import YoutubeIcon from "../../components/Icons/YoutubeIcon"
import MapPointerIcon from "../../components/Icons/MapPointerIcon"
import PaperPlanIcon from "../../components/Icons/PaperPlanIcon"
import PhoneLineIcon from "../../components/Icons/PhoneLineIcon"
import HoursIcon from "../../components/Icons/HoursIcon"
import PhoneIcon from "../../components/Icons/PhoneIcon"
import BreakpointDown from "../../components/Media/BreakpointDown"
import BreakpointUp from "../../components/Media/BreakpointUp"
import DefaultButton from "../../components/button/DefaultButton"
const FooterWrapper = styled.footer`
  margin:0;
  padding:0;
  background-color:#fff;
`
const ListInfo = styled.div`
  margin:0 0 20px;
`
const ListItemInfo = styled.div`  
  display:flex;
  flex-wrap:nowrap;  
  margin-bottom:0;
  padding:5px 0;
  > .icon{
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;    
    & svg{
      fill:#666;
      width:20px;
    }
    + .text{
      margin-left:15px;
    }
  }
  .text{
    color:#002337;
    font-size:14px;
    line-height:26px;
    ${BreakpointUp.xl`    
      font-size:16px;
      line-height:28px;
    `}
    label{
      display: block;
      line-height: 28px;
    }
    small{
      display: block;
      line-height: 20px;
    }
  }
  & a{
    display:inline-block;      
    color:#002337; 
    &:hover{
      color:#F99207;
      text-decoration:none;
    }
  }
  .e-mail{
    & a{
      text-decoration:underline;
      color:#002337;
      &:hover{
        color:#F99207;
        text-decoration:none;
      }
    }
  }
`
const MenuGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(5, auto);
  justify-content: space-between; 
  
	${BreakpointDown.lg`    
  grid-template-columns: repeat(3, minmax(0, 1fr));
  justify-content: space-evenly;
  `}
  
  ${BreakpointDown.md`    
    grid-template-columns: repeat(2, minmax(0, 1fr));
  `} 
  
  ${BreakpointDown.sm`        
    grid-template-columns: repeat(1, auto);	
    justify-content: inherit;
    ul{
      border-bottom: 1px solid #ddd;      
    }
  `}
`
const MenuCol = styled.div`
  margin:0;
  padding:0;
`
const MenuTitle = styled.div`
  color:#231F20;
  font-weight:700;
  margin-bottom:15px;  
  font-size:18px;
  line-height: 28px;
  ${BreakpointUp.xl`    
    font-size:20px;
    line-height: 30px;
  `}
`
const MenuList = styled.ul`
  list-style:none;
  margin:0 0 15px;
  padding:0;
`
const MenuItem = styled.li`
  margin-bottom: 10px;
  ${BreakpointUp.xl`    
    margin-bottom: 15px;
  `}
	& a {
    padding:5px 0;
		color:#959EA5;
    font-size:14px;
    line-height:26px;
    ${BreakpointUp.xl`    
      font-size:16px;
      line-height:28px;
    `}
		&:hover {
			color:#F99207;
		}
	}
`
const FooterTop = styled.div`
  position:relative;
`
const FooterMiddle = styled.div`
  padding:30px 0;
`
const FooterBottom = styled.div`
  color:rgba(102,102,102,0.7);
  border-top:1px solid #ddd;
  padding:30px 0 100px;
  ${BreakpointUp.md`    
    padding:30px 0;
  `}
`
const CopyrightGrid = styled.div`
  display:grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  align-items: center;
  ${BreakpointDown.sm`    
    justify-content: center;
    grid-template-columns: repeat(1, auto);
    gap: 10px;
  `}
`
const Copyright = styled.div`
  font-size:14px;
  line-height:24px;
  color:#959EA5;
  ${BreakpointUp.lg`
    font-size:16px;
    line-height:38px;
  `}
`
const SocialIcon = styled.ul`
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;  
  ${BreakpointDown.sm`    
    justify-content: inherit;
  `}  
`
const SocialItem = styled.li`
  list-style: none;    
  margin:0 6px;
  a{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid #DEDEDE;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
      fill:#9B9EA2;
    }
    &:hover{
      border-color:#000;
      svg{
        fill:#000;
      }
    }
  }
`
const FooterCta = styled.div`
  background-color: #fff;
  box-shadow: 0px -5px 25px 0px rgb(0 75 155 / 25%);
  width:100%;
  position: fixed;
  bottom: 0;
  left:0;
  right: 0;
  z-index: 20;
  text-align: center;
  display: block;  
  padding: 15px;
  ${BreakpointUp.md`
    display: none;  
  `}
  .btn{
    width: 100%;
  }
`
const Footer = () => (
  <FooterWrapper>
    <FooterTop>
      <RequestQuote ctaTitle="Trust Mr. Carports to Serve All Your Metal Building Needs" ctaDesc="Our experts are standing by to help you get the metal building of your dreams!" />
    </FooterTop>
    <FooterMiddle>
      <div className="container">
        <MenuGrid>
          <MenuCol>
            <MenuTitle>Our Buildings</MenuTitle>
            <MenuList>
              <MenuItem><Link to='/metal-carports'>Carports</Link></MenuItem>
              <MenuItem><Link to='/metal-garages'>Garages</Link></MenuItem>
              <MenuItem><Link to='/metal-barns'>Barns</Link></MenuItem>
              <MenuItem><Link to='/metal-buildings'>Metal Buildings</Link></MenuItem>
            </MenuList>
          </MenuCol>
          <MenuCol>
            <MenuTitle>Important Links</MenuTitle>
            <MenuList>
              <MenuItem><Link to='/financing'>Financing</Link></MenuItem>
              <MenuItem><Link to='/rto'>Rent to Own</Link></MenuItem>
              <MenuItem><Link to="/about-us">About Us</Link></MenuItem>
              <MenuItem><Link  to='/contact-us'>Contact us</Link></MenuItem>
              
                       
            </MenuList>
          </MenuCol>
          <MenuCol>
            <MenuTitle>Resources</MenuTitle>
            <MenuList>
              <MenuItem><Link to="/color-options">Color Options</Link></MenuItem>
              <MenuItem><Link to='/blog'>Blog</Link></MenuItem>   
              <MenuItem><Link to='/leveling-guide'>Leveling Guide</Link></MenuItem>
              <MenuItem><Link to='/metal-building-roof-guide'>Metal Building Roof Guide</Link></MenuItem>
              <MenuItem><Link to='/estimated-delivery'>Estimated Delivery</Link></MenuItem>
            </MenuList>
          </MenuCol>
          <MenuCol>
            <MenuTitle>Other Links</MenuTitle>
            <MenuList>
              <MenuItem><Link to="/privacy-policy">Privacy Policy</Link></MenuItem>
              <MenuItem><Link to="/sitemap">Sitemap</Link></MenuItem>
              <MenuItem><Link to='/faq'>Frequently Asked Questions</Link></MenuItem>
            </MenuList>
          </MenuCol>
          <MenuCol>
            <MenuTitle>Our Address</MenuTitle>
            <ListInfo className="list-info">
              <ListItemInfo>         
                <span className="icon"><MapPointerIcon /></span>
                <span className="text">P.O. Box 57 Dobson NC 27017</span>
              </ListItemInfo>

              <ListItemInfo> 
                <span className="icon"><PhoneLineIcon /></span>
                <span className="text"><a href="tel:8773505464">(877) 350-5464</a></span>
              </ListItemInfo>

              <ListItemInfo> 
                <span className="icon"><PaperPlanIcon /></span>
                <span className="text e-mail"><a href="mailto:sales@mrcarports.com">sales@mrcarports.com</a></span>   
              </ListItemInfo>

              <ListItemInfo>
                <span className="icon"><HoursIcon /></span>
                <span className="text">
                  <label aria-label="title">Operating Hours:</label>
                  <small>Monday to Friday -  8 AM to 5 PM</small>
                  <small>Saturday - 8 AM to 12 PM</small>
                </span> 
              </ListItemInfo>
            </ListInfo>
          </MenuCol>
        </MenuGrid>
      </div>  
    </FooterMiddle>
    
    <FooterBottom>
      <div className="container">
        <CopyrightGrid>
          <Copyright>© {new Date().getFullYear()} Mr. Carports. All rights reserved.</Copyright>          
          <SocialIcon>
            <SocialItem>
              <Link className="item-link" to="https://www.facebook.com/mrcarportsnc" target="_blank"><FacebookIcon /></Link>
            </SocialItem>
            <SocialItem>
              <Link className="item-link" to="https://www.instagram.com/mrcarports" target="_blank"><InstagramIcon /></Link>
            </SocialItem>
            <SocialItem>
              <Link className="item-link" to="https://twitter.com/mrcarportsnc" target="_blank"><TwitterIcon /></Link>
            </SocialItem>
            <SocialItem>
              <Link className="item-link" to="https://www.pinterest.com/mrcarportsnc/" target="_blank"><PinterestIcon /></Link>
            </SocialItem>
            <SocialItem>
              <Link className="item-link" to="https://www.youtube.com/channel/UCwD1OquJscV84xFEVFdyFYA" target="_blank"><YoutubeIcon  /></Link>
            </SocialItem>
          </SocialIcon>
        </CopyrightGrid>
      </div>
    </FooterBottom> 
    <FooterCta>
      <a href="tel:8773505464" aria-label="Phone"><DefaultButton animation="true" text="(877) 350-5464"  icon={<PhoneIcon />}/></a>
    </FooterCta>  
  </FooterWrapper>
)

export default Footer