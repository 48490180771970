import styled from "styled-components"
import { Link } from "gatsby"
import BreakpointUp from "../../components/Media/BreakpointUp"

export const ViewLink = styled(Link)`
	display: block;	
	font-size:16px;
	padding: 12px 0;
	color:#fff;
	font-weight:700;	
	${BreakpointUp.xl`  
		padding:6px 12px;
		text-align:center;	
		position:relative;
		margin: 20px 0;
		background: #f99207;
		background: -moz-linear-gradient(top,  #f99207 0%, #f97007 100%);
		background: -webkit-linear-gradient(top,  #f99207 0%,#f97007 100%);
		background: linear-gradient(to bottom,  #f99207 0%,#f97007 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f99207', endColorstr='#f97007',GradientType=0 );
		border-radius:4px;
		border: 1px solid #FF9F1D;		
	`}
`