import React from 'react'
import styled from 'styled-components'

const FormBtn  = styled.button`		
	background: #f99207;
	background: -moz-linear-gradient(top,  #f99207 0%, #f97007 100%);
	background: -webkit-linear-gradient(top,  #f99207 0%,#f97007 100%);
	background: linear-gradient(to bottom,  #f99207 0%,#f97007 100%);	
	color:#fff;
	display: inline-flex;
	align-items: center;
	justify-content: center;	
	text-align:center;	
	border:1px solid #FF9F1D;	
	padding:8px 24px;
	font-size:16px;
	font-weight:700;
	border-radius:4px;	
	outline:none;
	cursor: pointer;
	transition: color .5s ease-in-out,background-color .5s ease-in-out,border-color .5s ease-in-out,box-shadow .5s ease-in-out;
	> .text{
		line-height: 20px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		+ .icon{
			margin-left:10px;
		}
	}
	> .icon{
		height: 20px;		
		display: inline-flex;
		align-items: center;
		justify-content: center;
		& svg{
			fill:#fff;
		}	
		&:empty {
			display: none;
		}
	}
`

const FormButton = (props) => {
	const { icon, text } = props;
	return(
		<FormBtn className="btn" type="submit">
			<span className="icon">{icon}</span>
			<span className="text">{text}</span>
		</FormBtn>
	)
}

export default FormButton