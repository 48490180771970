import styled from "styled-components"
import BreakpointUp from "../../Media/BreakpointUp"

export const DropDown = styled.div`
	padding:0 40px;
	${BreakpointUp.xl`
		padding:20px 30px;  
		position: absolute;
		background-color:#fff;
		box-shadow: 0px 2px 25px rgba(0,0,0,0.3);
		max-width: 298px;
		min-width: 298px;
		width: 100%;
		top: 100%;
  	`}
`

export const DropItem = styled.div`
	& svg {	
		line-height: 22px;
	}
	& a {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding:12px 0;
		color: #fff;
		${BreakpointUp.xl`
			color: #231F20;
		`}
		& svg {
			transition: transform 0.3s ease-out;
			fill: #fff;
			${BreakpointUp.xl`
				fill: #231F20;
			`}
		}
		&:hover {
			svg{				
				transform: translateX(4px);
				fill: #231F20;
				${BreakpointUp.xl`
				fill: #F99207;
				`}
			}  
			color: #fff;
			${BreakpointUp.xl`
				color: #231F20;
			`}
		}
		${BreakpointUp.xl`  
			padding:15px 0;
		`}
	}	
`