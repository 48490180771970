import React from 'react'
import { Link } from 'gatsby'

import ArrowIcon from "../../Icons/ArrowIcon"
import { DropDown, DropItem } from "./tags-component"
import { Divider } from "../../section"

class ResourcesDropdown extends React.Component {
	render(){
		return(
			<DropDown className="dropdown-menu right">
				<DropItem><Link to='/leveling-guide'><span>Leveling Guide</span><ArrowIcon /></Link></DropItem>
				<Divider />
				<DropItem><Link to='/metal-building-roof-guide'><span>Metal Building Roof Guide</span><ArrowIcon /></Link></DropItem>
				<Divider />
				<DropItem><Link to='/estimated-delivery'><span>Estimated Delivery</span><ArrowIcon /></Link></DropItem>
				<Divider />
				<DropItem><Link to='/color-options'><span>Color Options</span><ArrowIcon /></Link></DropItem>
				<Divider />
				<DropItem><Link to='/blog'><span>Blog</span><ArrowIcon /></Link></DropItem>
				<Divider />
				<DropItem><Link to='/faq'><span>FAQs</span><ArrowIcon /></Link></DropItem>		
			</DropDown>
		)
	}
}

export default ResourcesDropdown