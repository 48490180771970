import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../../components/section'

const DefaultOutlineBtn  = styled(BaseButton)`		
	background: #fff;	
	border-color:#002337;
	color:#002337;
	> .icon{	
		& svg{
			fill:#002337;	
		}
	}
	&:hover{
		border-color:#000;	
		color:#000;		
		> .icon{
			& svg {
				fill:#000;
			}
		}
	}
`

const DefaultOutlineButton = (props) => {
	const { icon, text, className } = props;
	return(
		<DefaultOutlineBtn className={`btn ${className}`}>
			<span className="icon">{icon}</span>
			<span className="text">{text}</span>
		</DefaultOutlineBtn>
	)
}

export default DefaultOutlineButton